import ProForm, {
  ProFormDateRangePicker,
  ProFormSelect,
} from '@ant-design/pro-form';
import {BackendPostService} from '@common/api';
import {Button} from 'antd';
import {DefaultOptionType} from 'antd/lib/select';
import {CommonManager} from 'components/Common/Manager';
import {CommonTable} from 'components/Common/Table';
import {useApi} from 'hooks/useApi';
import {genRequest} from 'libs/genRequest';
import {useMemo, useState} from 'react';

const areas = [
  '大台北',
  '中山區.林森北路',
  '萬華.西門町',
  '板橋.中永和',
  '三重.新莊',
  '南港.內湖',
  '桃園',
  '中壢',
  '新竹',
  '台中',
  '北屯區',
  '西屯區.逢甲',
  '彰化.員林',
  '台南',
  '高雄',
  '嘉義.雲林',
  '宜蘭.花東',
];

const bolOpts: DefaultOptionType[] = [
  {label: '全部', value: ''},
  {label: '是', value: '1'},
  {label: '否', value: '0'},
];

export function BackendPostManager() {
  const findAll = useApi(BackendPostService.findAll);
  const [form] = ProForm.useForm();
  const [params, setParams] = useState({
    sortKey: 'postDate',
    sortType: 'DESC',
    isTop: '',
    isFree: '',
    area: areas,
  });

  const columns = useMemo(() => {
    return [
      CommonTable.Utils.Sorter({
        title: '日期時間',
        dataIndex: 'postDate',
        valueType: 'date',
      }),
      CommonTable.Utils.Sorter({title: 'id', dataIndex: 'username'}),
      CommonTable.Utils.Boolean(
        CommonTable.Utils.Sorter({
          title: '置頂',
          dataIndex: 'isTop',
        })
      ),
      CommonTable.Utils.Boolean(
        CommonTable.Utils.Sorter({
          title: '是否有空',
          dataIndex: 'isFree',
        })
      ),
      CommonTable.Utils.Sorter({
        title: '地區',
        dataIndex: 'area',
      }),
      CommonTable.Utils.Sorter({
        title: '電話',
        dataIndex: 'phone',
      }),
      CommonTable.Utils.Sorter({
        title: '文章標題',
        dataIndex: 'postTitle',
        render: (dom, row) => (
          <a href={row.postUrl} target="_blank">
            {row.postTitle}
          </a>
        ),
      }),
    ];
  }, []);

  return (
    <>
      <div style={{padding: 12, background: 'white', marginBottom: 12}}>
        <ProForm
          form={form}
          layout="inline"
          style={{gap: 8, flexWrap: 'wrap'}}
          onFinish={async values => {
            setParams(prev => ({
              ...prev,
              ...values,
            }));
          }}
          initialValues={params}
        >
          <ProForm.Item label="文章日期" name="postDate$range">
            <ProFormDateRangePicker />
          </ProForm.Item>
          <ProForm.Item label="置頂" name="isTop">
            <ProFormSelect options={bolOpts} allowClear={false} />
          </ProForm.Item>
          <ProForm.Item label="現在有空" name="isFree">
            <ProFormSelect options={bolOpts} allowClear={false} />
          </ProForm.Item>
          <div style={{flex: 1}}></div>
          <ProForm.Item label="地區" name="area" style={{width: '90%'}}>
            <ProFormSelect
              options={areas}
              allowClear={false}
              mode="tags"
              style={{width: '100%'}}
            />
          </ProForm.Item>
        </ProForm>
      </div>
      <CommonManager
        headerActions={[ExportExcel]}
        columns={columns}
        params={params}
        request={async (params, sort, filter) => {
          const result = await genRequest(findAll)(params, sort, filter);

          return result;
        }}
      />
    </>
  );
}

const ExportExcel: React.FC<any> = ({params, columns = [], sort}) => {
  const downloadExcel = useApi(BackendPostService.exportExcel);
  return (
    <Button
      onClick={async () => {
        const res = await downloadExcel({
          requestBody: {
            where: {
              ...params,
              area: params.area?.join(',') ?? '',
              isTop: params.isTop === '' ? undefined : params.isTop,
              isFree: params.isFree === '' ? undefined : params.isFree,

              current: null,
              pageSize: null,
            },
            keyword: params.keyword,
            sortKey: params.sortKey,
            sortType: params.sortType,
            columns,
          },
        });
        console.log('on export', res);
      }}
    >
      匯出Excel
    </Button>
  );
};
